import { app } from "./firebase";
import { getDatabase, ref, remove, set, update, off } from "firebase/database";

const db = getDatabase(app);

class dataService {
  getAll(tb) {
    return ref(db, tb);
  }

  getId(tb, key) {
    return ref(db, `${tb}/${key}`);
  }

  create(tb, payload) {
    return set(ref(db, `${tb}/${payload.uuid}`), payload);
  }

  update(tb, payload, key) {
    return update(ref(db, `${tb}/${key}`), payload);
  }

  delete(tb, key) {
    return remove(ref(db, `${tb}/${key}`));
  }

  off(tb) {
    return off(ref(db, tb));
  }
}

export default new dataService();
