<template>
  <div v-if="showPopup" class="popup-overlay">
    <div class="popup-content">
      <button class="close-btn" @click="closePopup">×</button>
      <a href="http://pmaquidauana.rcmsuporte.com.br:8080/servicosweb/imovel.jsf" target="_blank">
        <img src="@/assets/images/iptu-2025-1.jpg" alt="IPTU 2025" class="popup-image" />
      </a>
    </div>
  </div>
</template>


  <script>
  export default {
    name: "PopupDiario",
    data() {
      return {
        showPopup: false
      };
    },
    mounted() {
      this.checkPopupStatus();
    },
    methods: {
      checkPopupStatus() {
        const lastClosed = localStorage.getItem("popup_closed_date");
        const today = new Date().toISOString().split("T")[0];

        if (lastClosed !== today) {
          this.showPopup = true;
        }
      },
      closePopup() {
        const today = new Date().toISOString().split("T")[0];
        localStorage.setItem("popup_closed_date", today);
        this.showPopup = false;
      }
    }
  };
  </script>

  <style scoped>
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9000;
  }

  .popup-content {
    position: relative;
    background: white;
    border-radius: 8px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popup-image {
    width: 400px;
    height: 500px;
    object-fit: cover;
    border-radius: 8px;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: red;
    color: white;
    border: none;
    font-size: 24px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: self-end;
  }
  </style>
