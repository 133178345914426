import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import moment from "moment";
import "@babel/polyfill";
import "mutationobserver-shim";
import "./plugins/bootstrap-vue";
import "element-ui/lib/theme-chalk/index.css";
import "vue-lazy-youtube-video/dist/style.css";
import VueSocialSharing from "vue-social-sharing";
import { app } from "./services/firebase";
// import Share from "vue-share-ssr";
import "vue-share-ssr/dist/share.css";

// Vue.use(Share);
Vue.use(VueSocialSharing);
Vue.config.productionTip = false;
Vue.use(ElementUI);

//axios.defaults.baseURL = window.location.hostname === 'localhost' ? 'http://localhost:3050/api/' : 'https://api.aquidauana.ms.gov.br/api/'
//axios.defaults.baseURL = "http://localhost:8000/api/";
axios.defaults.baseURL = "https://api.aquidauana.ms.gov.br/api/";
Vue.prototype.$axios = axios;
Vue.prototype.$fire = app;

moment.locale("pt-br");
Vue.prototype.$moment = moment;
Vue.filter("formatDate", (value, format) => {
  return moment(value).format(format);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
