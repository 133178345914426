<template>
  <div class="carousel" v-if="items.length">
    <el-carousel
      indicator-position="none"
      arrow="always"
      v-if="itemsWeb.length && windowWidth > 650"
      :interval="10000"
    >
      <el-carousel-item v-for="item in itemsWeb" :key="item.id">
        <template v-if="item.link">
          <a :href="item.link" :target="item.link_janela === 1 ? '_blank' : '_self'">
            <img :src="item.imagem" alt="" />
          </a>
        </template>
        <template v-else>
          <img :src="item.imagem" alt="" />
        </template>
      </el-carousel-item>
    </el-carousel>
    <el-carousel
      indicator-position="none"
      arrow="always"
      v-else-if="itemsMobile.length && windowWidth <= 650"
      :interval="10000"
    >
      <el-carousel-item v-for="item in itemsMobile" :key="item.id">
        <template v-if="item.link">
          <a :href="item.link" :target="item.link_janela === 1 ? '_blank' : '_self'">
            <img :src="item.imagem_mobile" alt="" />
          </a>
        </template>
        <template v-else>
          <img :src="item.imagem_mobile" alt="" />
        </template>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "custom-carousel",
  props: ["items"],
  data() {
    return {
      windowWidth: 0,
      itemsMobileFiltered: [],
      itemsWebFiltered: [],
    };
  },
  watch: {
    items() {
      this.filterItems();
    },
  },
  computed: {
    itemsMobile: {
      set(items) {
        this.itemsMobileFiltered = items;
      },
      get() {
        return this.itemsMobileFiltered;
      },
    },
    itemsWeb: {
      set(items) {
        this.itemsWebFiltered = items;
      },
      get() {
        return this.itemsWebFiltered;
      },
    },
  },
  methods: {
    filterItems() {
      this.itemsMobile = this.items.filter(
        (item) => item.imagem_mobile !== null
      );
      this.itemsWeb = this.items.filter((item) => item.imagem !== null);
    },
  },
  created() {
    this.windowWidth = window.innerWidth;
    // this.filterItems();
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
};
</script>

<style lang="scss">
.carousel {
  .el-carousel__container {
    height: 454px !important;
    max-width: 100%;
  }
  .el-carousel__item img {
    height: 454px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }

  .el-carousel__arrow {
    height: 57px;
    width: 57px;
    // font-size: 26px;
    font-size: calc(var(--font-size--small) * 2);
    font-weight: bold;
    color: #d3dce6;
  }
}
@media only screen and (max-width: 1224px) {
  .carousel {
    .el-carousel__container {
      height: 324px !important;
    }
    .el-carousel__item img {
      height: 324px;
      width: 100%;
    }
  }
}
// @media only screen and (max-width: 768px) {
//   .el-carousel__container {
//     height: 324px !important;
//   }
//   .el-carousel__item img {
//     height: 324px;
//     width: 100%;
//   }
// }
@media only screen and (max-width: 650px) {
  .carousel {
    .el-carousel__container {
      height: 372px !important;
    }
    .el-carousel__item img {
      height: 372px;
      width: 100%;
    }
  }
}
</style>
