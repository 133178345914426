<template>
  <b-container class="plano-municipal" v-loading="loading">
    <h2>Semed Consulta</h2>
    <div class="plano-municipal__component">
      <p>
        A Comissão Municipal Intersetorial de Aquidauana convida toda a
        população para participar da construção do Plano Municipal pela Primeira
        Infância.
      </p>
      <p>
        Sua opinião é fundamental para garantir que o plano atenda às
        necessidades das nossas crianças.
      </p>
      <p>
        Acesse:
        <a
          href="https://docs.google.com/document/d/1RL0kdEgOXxBcuR6qkk1z8WBkTtCVtwqv/edit?usp=sharing&ouid=114347623991081017038&rtpof=true&sd=true"
          target="_blank"
        >
          https://docs.google.com/document/d/1RL0kdEgOXxBcuR6qkk1z8WBkTtCVtwqv/edit?usp=sharing&ouid=114347623991081017038&rtpof=true&sd=true
        </a>
      </p>
      <p>
        Envie suas sugestões até dia 07/09/2024 para
        <a href="mailto:semedaquidauana.setorpedagogico@gmail.com"
          >semedaquidauana.setorpedagogico@gmail.com</a
        >
      </p>
      <p>
        Juntos, vamos construir um futuro melhor para Aquidauana! Não perca essa
        oportunidade de fazer a diferença!
      </p>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "plano-municipal",
  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style lang="scss">
.plano-municipal {
  &.container {
    padding: 30px 20px;
    text-align: left;
  }

  font-family: Poppins;
  font-size: var(--font-size--default);

  h2 {
    color: var(--color-text-title);
    font-weight: bold;
    text-align: left;
  }

  p {
    margin-bottom: 20px;
    text-align: justify;
  }

  a {
    color: var(--color-primary);
    text-decoration: underline;
  }
}
</style>
