<template>
  <div class="lista-sec">
    <ul v-for="(item, index) in listagem" :key="index">
      <li>
        <img class="icone" :src="getImgUrl(item.icon)" alt="" />
        <span v-html="item.desc" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "lista-sec",
  props: {
    secretaria: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      listagem: [],
    };
  },
  methods: {
    getImgUrl(icone) {
      return require(`../../assets/images/${icone}`);
    },
    setList() {
      const lista = [];
      if (this.secretaria.nome) {
        lista.push({
          icon: "person.png",
          desc: this.secretaria.nome,
        });
      }
      if (this.secretaria.horario) {
        lista.push({
          icon: "watch.png",
          desc: `De segunda à sexta, das ${this.secretaria.horario} (horário de MS)`,
        });
      }
      if (this.secretaria.telefone) {
        lista.push({
          icon: "phone.png",
          desc: `Telefone: ${this.secretaria.telefone}`,
        });
      }
      if (this.secretaria.email) {
        lista.push({
          icon: "mail.png",
          desc: this.secretaria.email,
        });
      }
      if (this.secretaria.endereco) {
        lista.push({
          icon: "location.png",
          desc: this.secretaria.endereco,
        });
      }

      this.listagem = lista;
    },
  },
  created() {
    this.setList();
  },
};
</script>

<style lang="scss">
.lista-sec {
  display: flex;
  flex-direction: column;
  text-align: left;
  background: var(--background-color-primary);
  height: auto;
  min-height: 250px;
  padding-bottom: 50px;
  justify-content: center;
  ul {
    list-style: none;
    width: 50%;
    padding: 0px 40px;
    margin: 0;
    font-family: sans-serif;
    font-style: normal;
    font-weight: normal;

    &:first-child {
      padding: 20px 0 0 40px;
      font-weight: bold;
    }
    li {
      padding: 8px 40px;
      display: flex;
      align-items: center;
      .icone {
        height: 44px;
        margin-right: 10px;
      }
    }
  }
}

@media only screen and (max-width: 1224px) {
  .lista-sec {
    ul {
      padding: 0;
      width: 50%;
      &:first-child {
        padding: 0;
      }
      li {
        padding: 8px 0 0 50px;
        .icone {
          height: 36px;
          margin-right: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .lista-sec {
    min-height: 200px;
    padding-bottom: 10px;
    ul {
      padding: 0 !important;
      width: 100%;
      &:first-child {
        padding: 0;
      }
      li {
        padding: 8px 0 0 10px;
        .icone {
          height: 36px;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
