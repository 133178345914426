<template>
  <div :class="['links-area', color]" v-if="data.length">
    <div class="links-area wrapper">
      <h2>{{ title }}</h2>
      <div class="links-area__content" v-if="data.length">
        <div class="card-link-i" v-for="(card, index) in data" :key="index">
          <LinkCard :data="card" />
        </div>
      </div>
      <div class="see-all">
        <a href="/portal" class="text-right"
          ><span class="all-links">Ver todos</span></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import LinkCard from "@/components/linksArea/LinkCard";
export default {
  name: "links-area",
  components: {
    LinkCard,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    color: {
      type: String,
      required: false,
      default: "blue",
    },
  },
};
</script>

<style lang="scss">
.links-area {
  position: relative;
  width: calc(
    100vw - 20px
  ); /* Set the child container's width to 100% of the viewport width */
  left: 50%; /* Move the child container to the right by 50% of its own width */
  transform: translateX(-50%); /* Center the child container horizontally */
  &.wrapper {
    max-width: 1440px;
  }
  &.blue {
    background-color: var(--background-color-light);
  }
  &.green {
    background-color: var(--background-color-light-green);
  }
  h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: calc(var(--font-size--default) * 2);
    color: var(--background-color-secondary);
    text-align: left;
    padding: 35px 0 0 50px;
  }
  &__content {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
    .card-link-i {
      margin: 10px;
    }
  }
  .see-all {
    display: flex;
    justify-content: flex-end;
    padding: 0 30px 30px 0;
  }
  .text-right {
    width: 112px;
    height: 30px;
    background-color: var(--background-color-secondary);
    color: var(--background-color-primary);
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    &:active,
    &:hover {
      background-color: var(--background-color-hover-buttons) !important;
      text-decoration: none;
    }
  }
}
</style>
