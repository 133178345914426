<template>
  <b-container class="fornecedores">
    <h2>Fornecedores Cadastrados</h2>
    <h3 align="left" class="mt-5">Documentos</h3>
    <div class="fornecedores-area pt-4">
      <ul>
        <li v-for="fornecedor in fornecedoresDoc" :key="fornecedor.id">
          <a :href="fornecedor.arquivo" target="_blank">
            {{ fornecedor.titulo }} <span></span
          ></a>
        </li>
      </ul>
    </div>
    <h3 align="left" class="mt-5">
      Certificados de Registro Cadastral de Fornecedores
    </h3>
    <div class="fornecedores-area pt-4" v-loading="loading">
      <ul>
        <li v-for="fornecedor in fornecedores.data" :key="fornecedor.id">
          <a :href="fornecedor.arquivo" target="_blank">
            {{ fornecedor.titulo }} <span></span
          ></a>
        </li>
      </ul>
    </div>

    <div v-if="Object.keys(fornecedores).length > 0">
      <b-pagination
        class="pt-5"
        align="center"
        v-model="fornecedores.current_page"
        pills
        :total-rows="fornecedores.total"
        @change="handleChangePage"
        :per-page="fornecedores.per_page"
      ></b-pagination>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "fornecedores",
  data() {
    return {
      fornecedores: [],
      fornecedoresDoc: [
        {
          id: "1",
          titulo: "Convocação para Cadastro de Fornecedores 2024",
          arquivo:
            "https://arquivos.aquidauana.ms.gov.br/fornecedores/convocacao-cadastro-fornecedores-2024.pdf",
        },
        {
          id: "2",
          titulo:
            "Anexo 01 - Documentação para cadastro junto a Prefeitura Municipal de Aquidauana",
          arquivo:
            "https://arquivos.aquidauana.ms.gov.br/fornecedores/anexo-01-documenta%C3%A7%C3%A3o-para-cadastro-junto-a-prefeitura-municipal-de-aquidauana.pdf",
        },
      ],
      loading: false,
    };
  },
  methods: {
    async getFornecedores(pg = 1) {
      this.loading = true;
      this.fornecedores = [];
      window.scrollTo({
        top: 0,
      });
      const res = await this.$axios
        .get(`fornecedores?page=${pg}`)
        .catch((e) => {
          console.error("Fornecedores", e);
        });
      if (res.status === 200) {
        this.fornecedores = res.data;
      }
      this.loading = false;
    },
    handleChangePage(pg) {
      this.getFornecedores(pg);
    },
  },
  created() {
    this.getFornecedores();
  },
};
</script>

<style lang="scss" scoped>
.fornecedores {
  &.container {
    padding: 30px 20px;
    text-align: left;
  }
  font-family: Poppins;
  // font-size: 14px;
  font-size: var(--font-size--default);
  h4,
  h2,
  h3 {
    color: var(--color-text-title);
    font-weight: bold;
    text-align: left;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #dddddd;
      padding: 10px 20px;
      align-items: center;

      &:first-child {
        border-top: none;
      }
    }
  }
}
</style>
