<template>
  <b-container class="turismo">
    <div id="noticia">
      <h2>Inventário Turístico de Aquidauana</h2>
      <a
        href="https://arquivos.aquidauana.ms.gov.br/turismo/invtur.pdf"
        target="_blank"
        >Leia Aqui</a
      >
      <span v-html="dados"></span>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "turismo",
  data() {
    return {
      dados: `<h2 class='mt-2'>Informações Turísticas</h2>
<div id='noticia_exemplo'>
<p align='justify'>
<strong
>SECTUR – Secretaria Municipal de Cultura e Turismo de
Aquidauana</strong
><br />
- Endereço: R. Marechal Malet, n° 588 - Centro, 79210-000<br />
- Telefone: (67) 3240-1400 ramal: 1568<br />
- E-mail: sectur@aquidauana.ms.gov.br<br />
- Fanpage: fb.com/secturaquidauana<br />
</p>
<p align='justify'>
<strong>CAT - Centro de Atendimento ao Turista</strong><br />
- Endereço: R. Bichara Salamene s/nº Estação Ferroviária - Centro<br />
- Telefone: (67) 3240-1400 ramal: 1568<br />
- E-mail: sectur@aquidauana.ms.gov.br <br />
- Fanpage: fb.com/secturaquidauana
</p>
</div>

<h2>Agências e Operadoras de Receptivo</h2>
<div id='noticia_exemplo'>
<p align='justify'>
<strong>Guarany Viagens e Turismo</strong><br />
- Endereço: Rua Pândia Calógeras, nº 865 - Bairro Alto <br />
- Telefone: (67) 3241-3954 / (67) 99648-0640 <br />
- E-mail: pousadaguarany@outlook.com <br />
</p>
<p align='justify'>
<strong>Buriti Viagens e Turismo</strong><br />
- Endereço: Rua Estevão Alves Corrêa, nº 200 - Centro<br />
- Fone/Fax: (67) 3241-2718 / (67) 99969-4783<br />
- E-mail: buriti_turismo@hotmail.com<br />
</p>
<p align='justify'>
<strong>Clube do Marketing MS</strong><br />
- Endereço: Rua Marechal Mallet, nº 1391 - Centro<br />
- Fone/Fax: (67) 3241-1875/ (67) 99253-9044<br />
</p>
</div>

<h2>Meios de Hospedagens</h2>
<br />
<h2>Hotéis</h2>
<div id='noticia_exemplo'>
<p align='justify'>
<strong>Hotel Aquidauana Palace</strong><br />
- Endereço: Rua Manoel Antonio Paes de Barros, nº 904 - Centro<br />
- Telefone: (67) 3241-1602 / (67) 3241-5245<br />
- E-mail: aquidauanapalace@hotmail.com<br />
</p>
<p align='justify'>
<strong>Hotel Beira Rio</strong><br />
- Endereço: Rua João de Almeida Castro, nº 186 - Centro <br />
- Telefone: (67) 3241-6064<br />
- E-mail: contato@beirariopantanal.com.br<br />
</p>
<p align='justify'>
<strong>Portal Pantaneiro Hotel</strong><br />
- Endereço: Rua Pândia Calógeras, nº 1067 - Bairro Alto<br />
- Telefone: (67) 3241-4327 / (67) 3241-4328<br />
- E-mail: contato@portalpantaneiro.com.br<br />
</p>
<p align='justify'>
<strong>Hotel Tropical</strong><br />
- Endereço: Rua Manoel Aureliano da Costa, nº 630 - Centro<br />
- Telefone: (67) 3241-4113/ (67) 3241-1998<br />
- E-mail: eduardo_quelho@hotmail.com<br />
</p>
<p align='justify'>
<strong>Hotel Santa Izabel</strong><br />
- Endereço: Rua Manoel Aureliano da Costa, nº 674 - Centro<br />
- Telefone: (67) 3241-3214 <br />
- E-mail: hotelsantaizabel@terra.com.br<br />
</p>
<p align='justify'>
<strong>Pousada Estação Figueira</strong><br />
- Endereço: Rua Leonel Alves Corrêa, nº 63 - Bairro Alto<br />
- Telefone: (67) 3241-1342<br />
- E-mail: ronaldobuenodecastro@hotmail.com<br />
</p>
</div>

<h2>Pousadas Pantaneiras</h2>
<div id='noticia_exemplo'>
<p align='justify'>
<strong>Pousada Aguapé</strong><br />
- Telefone: (67) 3258-1146 / (67) 99986-0351 / (67) 99986-1215<br />
- Site: www.aguape.com.br<br />
- E-mail: pousadaaguape@terra.com.br / reservas@pousadaaguape.com.br
</p>
<p align='justify'>
<strong>Pequi Camping e Pesca </strong><br />
-Telefone: (67) 3245-0949 / (67) 99934-5781 / (67) 99986-0449 <br />
- Site: www.pousadapequi.com.br<br />
- E-mail: pousadapequi@yahoo.com.brr<br />
</p>
<p align='justify'>
<strong>Hotel Fazenda Baia das Pedras</strong><br />
- Telefone: (67) 3382-1275 / (67) 99912-8323 / (67) 99912-8324 <br />
- Site: www.baiadaspedras.com.br<br />
- E-mail: baiadaspedras@baiadaspedras.com.br
</p>
<p align='justify'>
<strong>Fazenda Barranco Alto</strong><br />
- Telefone: (67) 99654-3383<br />
- Site: www.fazendabarrancoalto.com.br<br />
- E-mail: barrancoalto@gmail.com <br />
</p>
<p align='justify'>
<strong>Hotel Recanto Barra Mansa </strong><br />
- Telefone: (67) 3325-6807 / (67) 99912-8918 <br />
Site: www.hotelbarramansa.com.br<br />
E-mail: info@hotelbarramansa.com.br <br />
</p>
<p align='justify'>
<strong>Pousada e Pesqueiro Carandá</strong><br />
- Endereço: Rodovia BR 262, s/nº - km 48, (60 km de Aquidauana)<br />
- Telefone: (67) 99964-3147 / (67) 99242-4203 / (67) 99980-1231<br />
- E-mail: fabianocorrea@hotmail.com<br />
</p>
</div>
<h2>Pousadas e Pesqueiros (Estrada Parque <br />de Piraputanga)</h2>
<div id='noticia_exemplo'>
<p align='justify'>
<strong>Chácara Bela Vista</strong><br />
- Endereço: Rua Luiz Miranda Horta, s/nº - Distrito de Piraputanga<br />
- Telefone: (67) 3257-1212 / (67) 98158-7796<br />
- E-mail: chabelavista@gmail.com<br />
</p>
<p align='justify'>
<strong>Chalé Piraputanga - Cama &amp; Café</strong><br />
- Endereço: Rua João de Deus, nº 67 - Distrito de Piraputanga<br />
- Telefone: (67) 99136-4000<br />
</p>
<p align='justify'>
<strong>Pesqueiro do Barbosa</strong><br />
- Endereço: MS-450, s/nº - Distrito de Piraputanga<br />
- Telefone: (67) 99292-7783<br />
</p>
<p align='justify'>
<strong>Pesqueiro do Barbosa</strong><br />
- Endereço: MS-450, s/nº - Distrito de Piraputanga<br />
- Telefone: (67) 99292-7783<br />
</p>
<p align='justify'>
<strong>Pesqueiro do Bill</strong><br />
- Endereço: MS-450, s/nº - Distrito de Piraputanga<br />
- Telefone: (67) 98423-1903 / (67) 98402-1407<br />
- E-mail: cleidecirferreira53@gmail.com<br />
</p>
<p align='justify'>
<strong>Pesqueiro, Pousada e Restaurante Serrano </strong><br />
- Endereço: MS-450, s/nº - Distrito de Piraputanga<br />
- Telefone: (67) 98423-1903 / (67) 98402-1407<br />
</p>
<p align='justify'>
<strong>Pousada Sítio Espelho do Céu </strong><br />
- Endereço: Furnas dos Baiano 2, s/nº - Distrito de Piraputanga<br />
- Telefone: (67) 99998-5278<br />
- E-mail: ivetecarreraspires@gmail.com<br />
</p>
<p align='justify'>
<strong> Chácara Moreira - Cama &amp; Café </strong><br />
- Endereço: Avenida Principal, s/nº - Distrito de Piraputanga<br />
- Telefone: (67) 99249-1730<br />
</p>
<p align='justify'>
<strong> Pousada e Camping Acampamento Batista </strong><br />
- Endereço: MS-450, s/nº - Distrito de Camisão<br />
- Telefone: (67) 3235-1047 / (67) 98403-1071<br />
- E-mail: elysouzafernandes@gmail.com<br />
</p>
<p align='justify'>
<strong> Pousada da Serra </strong><br />
- Endereço: Rua Carlos Perdigão, Lote 71, Colônia Paxixi - Distrito de
Camisão<br />
- Telefone: (67) 99830-8803<br />
- E-mail: beirariohotel@hotmail.com<br />
</p>
<p align='justify'>
<strong> Pesqueiro e Casa de Veraneio Chácara Morro do Chapéu </strong
><br />
- Endereço: Travessa do Trilho, s/nº - Distrito de Camisão<br />
- Telefone: (67) 98408-8413<br />
</p>
<p align='justify'>
<strong> Pesqueiro 3 Maria </strong><br />
- Endereço: Distrito de Camisão<br />
- Telefone: (67) 99995-3725 / (67) 99918-4552<br />
- E-mail: pesqueiro_3maria@hotmail.com<br />
</p>
<p align='justify'>
<strong> Pesqueiro Sonho Meu </strong><br />
- Endereço: Distrito de Camisão<br />
- Telefone: (67) 99971-2028<br />
</p>
<p align='justify'>
<strong> Pesqueiro 3 Maria </strong><br />
- Endereço: Distrito de Camisão<br />
- Telefone: (67) 99652-7069<br />
</p>
</div>

<h2>Loja de Artesanato e Souvenirs</h2>
<div id='noticia_exemplo'>
<p align='justify'>
<strong>Casa do Artesão</strong><br />
- Endereço: Rua Bichara Salamene, s/nº, Estação Ferroviária -
Centro<br />
- Telefone: (67) 3240-1400 ramal: 1568 / 1569 <br />
- E-mail: casa.artesao.aquidauana@gmail.com<br />
</p>
</div>

<h2>Museus</h2>
<div id='noticia_exemplo'>
<p align='justify'>
  <strong>Museu de Arte Pantaneira Manoel Antônio Paes de Barros</strong><br />
- Endereço: Rua Cândido Mariano, nº 462 - Centro.<br />
- Telefone: (67) 3240-1400 - ramal: 1572/ 1573.<br />
- E-mail: museuartepantaneira@aquidauana.gov.ms.br.<br />
- Visitação – Segunda-feira a sexta-feira, das 8h às 18h, inclusive no horário do almoço.<br />
- As visitas aos sábados deverão ser agendadas com antecedência.<br />
- Visitas em grupo para mais de 20 pessoas (escolas, instituições e entidades), deverão ser
agendadas com antecedência pelo telefone.<br />
- A entrada no Museu é gratuita.
- Agendar visita pelo link: <a class="link" href="https://forms.gle/SAJ4QUjpLgrjmBSn6" target="_blank">https://forms.gle/SAJ4QUjpLgrjmBSn6</a><br />
</p>
<p align='justify'>
  <strong>Museu Marechal José Machado Lopes</strong><br />
- Endereço: Rua Duque de Caxias, s/nº - Bairro Alto.<br />
- E-mail: marechaljosemachadolopes@gmail.com.<br />
- Visitação: Somente em dias úteis. De terça-feira a quinta-feira, das 9h45min às 11h e das
13h45min às 16h. Sexta-feira das 8h30min às 11h30min. Segunda-feira: fechado para
manutenção.<br />
- Visitas em grupo para mais de 10 pessoas deverão ser agendadas com antecedência.<br />
- Agendar visita pelo link: <a class="link" href="https://docs.google.com/forms/d/1HDABPCT2h-ea-
YepLZLNrBEcxJw_IfCRfRdtMxez4z0/viewform?edit_requested=true" target="_blank">https://docs.google.com/forms/d/1HDABPCT2h-ea-
YepLZLNrBEcxJw_IfCRfRdtMxez4z0/viewform?edit_requested=true</a><br />
- A entrada no Museu é gratuita.
</p>
</div>
<h2>Restaurantes, Lanchonetes e Pizzarias</h2>
<div id='noticia_exemplo'>
<p align='justify'>
<strong>Alziro Lanches</strong><br />
- Endereço: Rua Bichara Salamene, nº 855 - Bairro Guanandy<br />
- Telefone: (67) 3241-3049 / (67) 99851-6963<br />
</p>
<p align='justify'>
<strong>Angela Ricalde Doces e Salgados</strong><br />
- Endereço: Rua Augusto Mascarenhas, nº 543 - Centro<br />
- Telefone: (67) 99248-5828<br />
</p>
<p align='justify'>
<strong>Atlântico Super Center </strong><br />
- Endereço: R. Oscar Trindade de Barros, 1169 - Santa Therezinha<br />
- Telefone: (67) 3241-2960 <br />
</p>
<p align='justify'>
<strong>Cantinho do Peixe </strong><br />
- Endereço: R. Manoel A. Paes de Barros, 1767 - Guanandy<br />
- Telefone: (67) 99944-1911 / (67) 99280-5795<br />
</p>
<p align='justify'>
<strong>Delivery Amorim </strong><br />
- Endereço: Rua Manoel Antônio Paes de Barros, 943 - Centro<br />
- Telefone: (67) 99253-9044 / (67) 99212-6101<br />
</p>
<p align='justify'>
<strong>Doceria Mineira </strong><br />
- Endereço: Rua Estevão Alves Corrêa, 547 - Centro<br />
- Telefone: (67) 3241-2997<br />
</p>
<p align='justify'>
<strong>Dom Josimo</strong><br />
- Endereço: Rua Estevão Alves Corrêa, 944 - Bairro Alto<br />
- Telefone: (67) 99647-5890<br />
</p>
<p align='justify'>
<strong>Esquinão Lanches (Esquina do Peixe)</strong><br />
- Endereço: Avenida Pantaneta, n° 1 - Bairro Alto<br />
- Telefone: (67) 99109-5390<br />
</p>
<p align='justify'>
<strong>Genésio Cervejaria</strong><br />
- Endereço: Avenida Pantaneta, n° 420 - Bairro Alto<br />
- Telefone: (67) 99658-7504<br />
</p>
<p align='justify'>
<strong>Lá no Bistrô da Mari</strong><br />
- Endereço: Rua Estevão Alves Corrêa, nº 1059 - Bairro Alto<br />
- Telefone: (67) 98466-4087<br />
</p>
<p align='justify'>
<strong>Nihon Restaurante Japonês</strong><br />
- Endereço: Rua Sete de Setembro, nº 1544 - Bairro Guanandy<br />
- Telefone: (67) 3241-1686 / (67) 99247-9319 <br />
</p>
<p align='justify'>
<strong>Nihon Restaurante Japonês</strong><br />
- Endereço: Rua Sete de Setembro, nº 1544 - Bairro Guanandy<br />
- Telefone: (67) 3241-1686 / (67) 99247-9319 <br />
</p>
<p align='justify'>
<strong>Panificadora Viana </strong><br />
- Endereço: R. Marechal Mallet, 773 - Centro <br />
-Telefone: (67) 3241-2654 / (67) 99133-4683 <br />
</p>
<p align='justify'>
<strong>Pastelaria Uai </strong><br />
- Endereço: Rua Sete de Setembro, nº 1010 - Centro<br />
- Telefone: (67) 3241-3143 <br />
</p>
<p align='justify'>
<strong>Pizza 10 </strong><br />
-Endereço: Av. Pantaneta, 429 – Bairro Alto<br />
-Telefone: (67) 3241-1010 / (67) 99661-1021<br />
</p>
<p align='justify'>
<strong>Pizza Jóia </strong><br />
-Endereço: Av. Pantaneta, 272 – Bairro Alto<br />
-Telefone: (67) 99843-7366<br />
</p>
<p align='justify'>
<strong>Pizzaria Fiorella </strong><br />
- Endereço: R. Duque de Caxias, 1171 - Bairro Alto<br />
-Telefone: (67) 3241-1009 <br />
</p>
<p align='justify'>
<strong>Ponto Expesso</strong><br />
- Endereço: Av. Pantaneta, 388 - Bairro Alto <br />
- Telefone: (67) 3241-3022 <br />
</p>
<p align='justify'>
<strong>Restaurante Colher de Pau</strong><br />
- Endereço: R. Teodoro Rondon, 386 - Centro<br />
- Telefone: (67) 3241-1048<br />
</p>
<p align='justify'>
<strong>Restaurante e Choperia Moderna</strong><br />
- Endereço: Rua Augusto Mascarenhas, nº 573 – Centro<br />
- Fone/Fax: (67) 3241-8100<br />
</p>
<p align='justify'>
<strong>Restaurante Estação Pantaneira</strong><br />
- Endereço: Rua Bichara Salamene, s/nº – Centro (Estação
Ferroviária)<br />
- Fone: (67) 3241-1140<br />
</p>
<p align='justify'>
<strong>Restaurante O Casarão</strong><br />
- Endereço: Rua Manoel Antonio de Barros, nº 533 – Centro<br />
- Fone/Fax: (67) 3241-2219<br />
</p>
<p align='justify'>
<strong>Rústico Café &amp; Grill</strong><br />
- Endereço: Rua Manoel Antônio Paes de Barros, nº 528 - Centro<br />
- Telefone: (67) 3241-5125<br />
</p>
<p align='justify'>
<strong>Texas Burger </strong><br />
- Endereço: Av. Pantaneta, espaço 6 – Bairro Alto<br />
- Telefone: (67) 99843-7366 <br />
</p>
<p align='justify'>
<strong>Zum Zum Zum Bar e Restaurante </strong><br />
- Telefone: (67) 99613-4705 / (67) 99672-1427<br />
- Endereço: Rua Bichara Salamene, nº 64 - Centro<br />
</p>
<h3>Estrada Parque de Piraputanga</h3>
<p align='justify'>
<strong>Conveniência Amigos do Vovô </strong><br />
-Endereço: Avenida Camisão (MS 450) – Distrito de Camisão <br />
-Telefone: (67) 3235-1056 / (67) 99275-2183<br />
</p>
<p align='justify'>
<strong>Delícias do Paxixi </strong><br />
- Endereço: Av. Camisão (MS 450) – Distrito de Camisão<br />
- Telefone: (67) 3241-2960 <br />
</p>
<p align='justify'>
<strong>Espaço Cultural Raiz</strong><br />
- Endereço: Rua Líbero Badaró, s/nº - Distrito de Piraputanga<br />
- Telefone: (67) 99647-5361 <br />
</p>
<p align='justify'>
<strong>La Garcia Restaurante</strong><br />
- Endereço: Luiz Miranda Horta 490 – Distrito de Piraputanga <br />
- Telefone: (67) 99804-0254<br />
</p>
<p align='justify'>
<strong>Pira Restaurante e Conveniência</strong><br />
- Endereço: Av. Presidente Getúlio Vargas, s/nº - Distrito de
Piraputanga<br />
Tel: (67) 3257-1071<br />
</p>
<p align='justify'>
<strong>Recanto Tomazi</strong><br />
- Endereço: MS-450, s/nº - Distrito de Piraputanga<br />
- Telefone: (67) 99930-6605<br />
</p>
<p align='justify'>
<strong>Serra Verde Restaurante &amp; Café</strong><br />
- Endereço: MS-450, km 20, s/nº - Distrito de Piraputanga<br />
- Telefone: (67) 99157-0122 <br />
</p>
</div>`,
    };
  },
};
</script>

<style lang="scss">
.turismo {
  &.container {
    padding: 30px 20px;
    text-align: left;
  }
  font-family: Poppins;
  // font-size: 14px;
  font-size: var(--font-size--default);
  h4,
  h2 {
    color: var(--color-text-title);
    font-weight: bold;
    text-align: left;
  }
  h3 {
    font-weight: bold;
    text-align: left;
  }
  .link {
    color: var(--color-text-title);
  }
}
</style>
