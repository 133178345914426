<template>
  <b-container class="lei-aldir" v-loading="loading">
    <h2>Programa Auxílio Brasil</h2>
    <div class="lei-aldir__component">
      <h3>CADASTRO ÚNICO</h3>
      <p>
        O Cadastro Único é um grande mapa das famílias de baixa renda no Brasil.
        Ele mostra ao governo quem essas famílias são, como elas vivem e do que
        elas precisam para melhorar suas vidas.
      </p>
      <p>
        É através do Cadastro Único que são avaliados os vários programas
        sociais dos governos, como:
      </p>
      <ul>
        <li>Bolsa Família;</li>
        <li>Tarifa Social de Energia Elétrica;</li>
        <li>Cuidar de Quem Cuida;</li>
        <li>Pé-de-Meia;</li>
        <li>Dignidade Menstrual;</li>
        <li>BPC-LOAS;</li>
        <li>Carteira da Pessoa Idosa;</li>
        <li>Entre outros.</li>
      </ul>
      <p>
        Para inscrever-se no Cadastro Único basta ir até o CRAS, levar os
        documentos de todas as pessoas que moram na casa e o comprovante de
        residência (preferência pela conta de luz).
      </p>
      <p>
        <strong>Atenção:</strong> O Cadastro Único é feito somente
        presencialmente no CRAS.
      </p>
      <p>CRAS I – São Pedro: Rua Mário Guerreiro, n.º 1505, Bairro São Pedro</p>
      <p>
        CRAS II – Nova Aquidauana: Av. Mato Grosso do Sul, n.º 262, Bairro Nova
        Aquidauana.
      </p>
      <p>
        O cadastro precisa ser atualizado dentro de dois anos, mas se houver
        alguma alteração na composição familiar, renda, crianças na escola ou
        endereço, a família deve procurar o CRAS para realizar a atualização.
      </p>

      <h3>PROGRAMA BOLSA FAMÍLIA</h3>
      <p>
        O Programa Bolsa Família é um programa de transferência condicionada de
        renda.
      </p>
      <p>
        Para a família receber, ela precisa estar inscrita no Cadastro Único
        para programas sociais do governo federal e possuir uma renda per capita
        de até R$ 218,00. A renda per capita é a renda total que a família
        possui dividida pela quantidade de pessoas que residem na casa. A
        família precisa manter as informações de renda, endereço e composição
        familiar sempre atualizados.
      </p>
      <p>
        A família que recebe o Programa Bolsa Família precisa cumprir as
        Condicionalidades (regras) para continuar recebendo o benefício.
      </p>

      <h3>VAMOS CONHECÊ-LAS!</h3>
      <p>
        Manter o Cadastro Único sempre atualizado com os dados de renda,
        endereço, composição familiar, escolas onde as crianças estudam.
        Lembre-se que as informações devem ter o prazo máximo para atualização
        de 2 anos. A família deve procurar o seu CRAS de referência sempre que
        houver alguma mudança em sua família.
      </p>
      <p>
        <strong>SAÚDE:</strong> A família deve fazer o acompanhamento da saúde
        que visa fazer a imunização e o acompanhamento nutricional das crianças
        de 0 a 7 anos de ambos os sexos e dos 14 aos 44 anos somente para as
        mulheres, a fim de fazer também o acompanhamento gestacional caso alguém
        da família esteja grávida.
      </p>
      <p>
        <strong>EDUCAÇÃO:</strong> É importante que a família mantenha as
        crianças em idade escolar (4 aos 17 anos de idade) matriculadas na
        escola e com a frequência mínima exigida por mês. Lembrando que a
        criança ou jovem só deixa de ser acompanhado pelo programa quando
        completar 18 anos de idade ou terminar o ensino médio.
      </p>
      <p>
        Se houver descumprimento de alguma dessas regras, a família pode ter o
        seu benefício bloqueado, suspenso ou até mesmo cancelado.
      </p>

      <h3>QUANDO O BENEFICIÁRIO CONSEGUE UM EMPREGO</h3>
      <p>
        Caso a família já seja beneficiária do programa Bolsa Família e algum
        membro da família passe a ter uma renda (emprego) e a renda per capita
        da família fique superior a R$ 218,00 e abaixo de meio salário mínimo
        (R$ 706,00), essa família entra na chamada Regra de Proteção.
      </p>
      <p>
        Na Regra de Proteção, a família passa a receber metade do valor do
        benefício que recebia, mesmo estando empregada, por um período de até 2
        anos. Caso perca a renda, a família tem direito de retorno imediato ao
        programa.
      </p>

      <h3>MAIS INFORMAÇÕES</h3>
      <p>Fone: (67) 99169-5670</p>
      <p>CRAS I – São Pedro: Rua Mário Guerreiro, n.º 1505, Bairro São Pedro</p>
      <p>
        CRAS II – Nova Aquidauana: Av. Mato Grosso do Sul, n.º 262, Bairro Nova
        Aquidauana
      </p>
      <p>Horário de atendimento: 7h às 11h e 13h às 17h</p>
      <br />
      <img
        src="https://arquivos.aquidauana.ms.gov.br/auxilio-brasil/banner_pag_net_final.gif"
        style="max-height: 300px"
      />
      <br />
      <ul v-if="active">
        <li v-for="data in lei" :key="data.id">
          <a :href="data.arquivo" target="_blank">{{ data.titulo }}</a>
        </li>
      </ul>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "auxilio-brasil",
  data() {
    return {
      lei: [],
      loading: false,
      active: true,
    };
  },
  methods: {
    async getDados() {
      this.loading = true;
      this.lei = [];
      const res = await this.$axios.get(`auxilio-brasil`).catch((e) => {
        console.error("Auxilio Brasil", e);
      });
      if (res.status === 200) {
        this.lei = res.data;
      }
      this.loading = false;
    },
  },
  created() {
    this.getDados();
  },
};
</script>

<style lang="scss">
.lei-aldir {
  &.container {
    padding: 30px 20px;
    text-align: left;
  }

  font-family: Poppins;
  // font-size: 14px;
  font-size: var(--font-size--default);

  h4,
  h2 {
    color: var(--color-text-title);
    font-weight: bold;
    text-align: left;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      border-top: 1px solid #dddddd;
      padding: 10px 20px;
      text-align: justify;

      &:first-child {
        border-top: none;
      }
    }
  }
}

@media only screen and (max-width: 936px) {
}
</style>
