import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import SiteTemplate from "@/templates/SiteTemplate.vue";
import PortalTemplate from "@/templates/PortalTemplate.vue";
import Portal from "@/views/Portal.vue";
import Cidade from "@/views/Cidade.vue";
import Turismo from "@/views/Turismo.vue";
import Fornecedores from "@/views/Fornecedores.vue";
import Licitacoes from "@/views/Licitacoes.vue";
import Legislacao from "@/views/Legislacao.vue";
import ConcursoPublico from "@/views/ConcursoPublico.vue";
import ControleInterno from "@/views/ControleInterno.vue";
import Prefeitura from "@/views/Prefeitura.vue";
import GaleriaPrefeitos from "@/views/GaleriaPrefeitos.vue";
import Ouvidoria from "@/views/Ouvidoria.vue";
import Concursos from "@/views/Concursos.vue";
import Convenios from "@/views/Convenios.vue";
import LeiAldirBlanc from "@/views/LeiAldirBlanc.vue";
import Pnab from "@/views/Pnab.vue";
import LeiPauloGustavo from "@/views/LeiPauloGustavo.vue";
import AuxilioBrasil from "@/views/AuxilioBrasil.vue";
import PlanoPluriAnual from "@/views/PlanoPluriAnual.vue";
import CalendarioEventos from "@/views/CalendarioEventos.vue";
import PlanejamentoSaude from "@/views/PlanejamentoSaude.vue";
import ProfessoresTemporarios from "@/views/ProfessoresTemporarios.vue";
import VacinadosCovid from "@/views/VacinadosCovid.vue";
import ConcursoLiterario from "@/views/ConcursoLiterario.vue";
import Search from "@/views/Search.vue";
import CidadeDigital from "@/views/CidadeDigital.vue";
import Pme from "@/views/Pme.vue";
import LegalNotes from "@/views/LegalNotes.vue";
import Videos from "@/views/Videos.vue";
import Galerias from "@/views/Galerias.vue";
import Teste from "@/views/Teste.vue";
import Erro404 from "@/templates/404.vue";
import SemedConsulta from "@/views/SemedConsulta.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/portal",
    component: PortalTemplate,
    children: [
      {
        path: "",
        name: "Portal",
        component: Portal,
      },
      {
        path: "teste",
        name: "Teste",
        component: Teste,
      },
      {
        path: "*",
        name: "error404Portal",
        component: Erro404,
      },
    ],
  },
  {
    path: "/",
    component: SiteTemplate,
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "/cidade",
        name: "Cidade",
        component: Cidade,
      },
      {
        path: "/turismo",
        name: "Turismo",
        component: Turismo,
      },
      {
        path: "/fornecedores",
        name: "Fornecedores",
        component: Fornecedores,
      },
      {
        path: "/licitacoes",
        name: "Licitacoes",
        component: Licitacoes,
      },
      {
        path: "/legislacao",
        name: "Legislacao",
        component: Legislacao,
      },
      {
        path: "/concurso-publico",
        name: "ConcursoPublico",
        component: ConcursoPublico,
      },
      {
        path: "/controle-interno",
        name: "ControleInterno",
        component: ControleInterno,
      },
      {
        path: "/lei-aldir-blanc",
        name: "LeiAldir",
        component: LeiAldirBlanc,
      },
      {
        path: "/pnab",
        name: "Pnab",
        component: Pnab,
      },
      {
        path: "/lei-paulo-gustavo",
        name: "LeiPauloGustavo",
        component: LeiPauloGustavo,
      },
      {
        path: "/auxilio-brasil",
        name: "auxilioBrasil",
        component: AuxilioBrasil,
      },
      {
        path: "/plano-pluri-anual",
        name: "PlanoPluriAnual",
        component: PlanoPluriAnual,
      },
      {
        path: "/semed-consulta",
        name: "semed",
        component: SemedConsulta,
      },
      {
        path: "/pme",
        name: "Pme",
        component: Pme,
      },
      {
        path: "/vacinados-covid",
        name: "VacinadosCovid",
        component: VacinadosCovid,
      },
      {
        path: "/concurso-literario",
        name: "ConcursoLiterario",
        component: ConcursoLiterario,
      },
      {
        path: "/calendario-eventos",
        name: "CalendarioEventos",
        component: CalendarioEventos,
      },
      {
        path: "/planejamento-saude",
        name: "PlanejamentoSaude",
        component: PlanejamentoSaude,
      },
      {
        path: "/professores-temporarios",
        name: "ProfessoresTemporarios",
        component: ProfessoresTemporarios,
      },

      {
        path: "/ouvidoria",
        name: "Ouvidoria",
        component: Ouvidoria,
      },
      {
        path: "/cidades-digitais",
        name: "CidadeDigital",
        component: CidadeDigital,
      },
      {
        path: "/concursos",
        name: "Concursos",
        component: Concursos,
      },
      {
        path: "/convenios",
        name: "Convenios",
        component: Convenios,
      },
      {
        path: "/prefeitura",
        name: "Prefeitura",
        component: Prefeitura,
      },
      {
        path: "/prefeitos",
        name: "GaleriaPrefeitos",
        component: GaleriaPrefeitos,
      },
      {
        path: "/noticias",
        name: "Noticias",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/News.vue"),
      },
      {
        path: "/noticia/:id/:title",
        name: "Noticia",
        component: () => import("../views/Noticias.vue"),
      },
      {
        path: "/galeria/:id/:title",
        name: "Galeria",
        component: () => import("../views/Galeria.vue"),
      },
      {
        path: "/search/:title",
        name: "Search",
        component: Search,
      },
      {
        path: "/legal-notes",
        name: "LegalNotes",
        component: LegalNotes,
      },
      {
        path: "/videos",
        name: "Videos",
        component: Videos,
      },
      {
        path: "/galerias",
        name: "Galerias",
        component: Galerias,
      },
      {
        path: "/secretaria/:id/:title",
        name: "Secretaria",
        component: () => import("../views/Secretarias.vue"),
      },
      {
        path: "/museu",
        name: "Museu",
        component: () => import("../views/Museu.vue"),
      },
      {
        path: "*",
        name: "error404Site",
        component: Erro404,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);
  // ...
  next();
});

export default router;
