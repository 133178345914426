<template>
  <b-container class="prefeitura" v-loading="loading">
    <div class="prefeitura-content mb-5" v-if="Object.keys(prefeitura).length">
      <Topo :secretaria="prefeitura" />
      <Lista :secretaria="prefeitura" />
      <hr />
      <div
        class="vice-prefeito mt-4"
        v-if="prefeitura.nome_vice || prefeitura.foto_vice"
      >
        <div class="d-flex align-items-center">
          <div class="mr-3 ml-5 text-left vice-prefeito-title">
            <h5 class="mb-2">Vice-Prefeito</h5>
            <ul class="list-unstyled ul-sec">
              <li class="li-sec">
                <img
                  src="../assets/images/person.png"
                  alt=""
                  class="icone"
                /><span class="font-bold">{{ prefeitura.nome_vice }}</span>
              </li>
            </ul>
          </div>
          <div class="vice-foto">
            <img
              v-if="prefeitura.foto_vice"
              :src="prefeitura.foto_vice"
              alt="Foto do Vice-Prefeito"
              class="img-fluid"
            />
          </div>
        </div>
      </div>

      <div class="see-all">
        <a href="/prefeitos" class="see-all-left"
          ><span class="all-links">Ver todos os Prefeitos</span></a
        >
      </div>
      <Mapa :coord="prefeitura.coordenada" />
      <Tabs :secretaria="prefeitura" />
    </div>
  </b-container>
</template>

<script>
import Topo from "../components/Secretarias/Topo.vue";
import Lista from "@/components/Secretarias/Lista.vue";
import Mapa from "../components/Secretarias/Mapa.vue";
import Tabs from "../components/Secretarias/Tabs.vue";

export default {
  components: {
    Topo,
    Lista,
    Mapa,
    Tabs,
  },
  name: "prefeitura",
  data() {
    return {
      loading: false,
      prefeitura: {},
    };
  },
  methods: {
    async getPrefeitura() {
      this.loading = true;
      this.secretaria = {};
      const res = await this.$axios.get("prefeitura").catch((e) => {
        console.error("Prefeitura", e);
      });
      if (res.status === 200) {
        this.prefeitura = res.data;
      }
      console.log(this.prefeitura);
      this.loading = false;
    },
  },
  created() {
    this.getPrefeitura();
  },
};
</script>

<style lang="scss">
.prefeitura {
  padding: 0;
  min-height: 300px;

  .see-all {
    display: flex;
    justify-content: flex-start;
    padding: 0 20px 10px 5%;

    &-left {
      width: 200px;
      height: 30px;
      background-color: var(--background-color-secondary);
      color: var(--background-color-primary);
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      display: flex;
      &:active,
      &:hover {
        background-color: var(--background-color-hover-buttons) !important;
        text-decoration: none;
      }
    }
  }
}

.li-sec {
  padding: 8px 40px 0 0;
  display: flex;
  align-items: center;
  .icone {
    height: 44px;
    margin-right: 10px;
  }
}

.ul-sec {
  ul:first-child {
    padding: 20px 0 0 40px;
    font-weight: bold;
  }
}

.font-bold {
  font-weight: bold;
}

.vice-prefeito {
  padding: 0 40px;
  .vice-foto {
    height: 260px; // Define a altura fixa da imagem
    width: auto; // Ajusta a largura automaticamente para manter a proporção
    margin-left: auto; // Alinha à direita
    img {
      height: 100%; // Garante que a imagem ocupe a altura definida
      width: auto; // Mantém a proporção da imagem
      object-fit: cover; // Corta e ajusta a imagem para preencher o espaço
      border-radius: 15px; // Adiciona bordas arredondadas
    }
  }
}

@media only screen and (max-width: 1200px) {
  .vice-prefeito {
    padding: 0 40px 0 20px;
  }
}

@media only screen and (max-width: 990px) {
  .vice-prefeito {
    padding: 0 20px;
    & > div {
      display: flex;
      flex-direction: column-reverse;
      ul {
        padding: 0;
      }
    }
    .vice-foto {
      width: 100%;
    }

    &-title {
      width: 100%;
      margin-top: 20px;
      & > h5 {
        text-align: center;
      }
    }
  }
}
</style>
